<template>
	<v-app v-if="isThemeFetched">
		<admin-default-app-bar />
		<admin-default-drawer />
		<admin-default-view />
		<footer>
			<div class="row">
				<div class="col">
					<img src="~@/assets/whsa_logo.svg" />
				</div>
				<div class="col text-right">
					<ul class="list-inline">
						<li><a href="">Privacy Policy</a></li>
						<li><a href="">Terms of Service</a></li>
					</ul>
				</div>
			</div>
		</footer>
	</v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "AdminLayout",

	components: {
		AdminDefaultView: () =>
			import(
				/* webpackChunkName: "admin-default-view" */
				"./AdminDefaultView"
			),
		AdminDefaultDrawer: () =>
			import(
				/* webpackChunkName: "admin-default-drawer" */
				"./AdminDefaultDrawer"
			),
		AdminDefaultAppBar: () =>
			import(
				/* webpackChunkName: "admin-default-app-bar" */
				"./AdminDefaultAppBar"
			)
	},
	computed: {
		...mapGetters(["isThemeFetched", "organization"]),
		organizationId() {
			if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		}
	},
	methods: {
		...mapActions([
			"getOrganizationInfo",
			"getCoursesTypes",
			"getCourses",
			"getDeletedCourses",
			"getQuizzes",
			"getDeletedQuizzes"
		])
	},
	created() {
		this.getOrganizationInfo();
		this.getCoursesTypes();
		this.getCourses();
		this.getDeletedCourses();
		this.getQuizzes();
		this.getDeletedQuizzes();
	},
	async beforeMount() {
		localStorage.setItem("organizationId", this.organizationId);
		await this.$store.dispatch("getOrganizationInfo", this.organizationId);
		await this.$store.dispatch("setThemeColors");

		this.$store.dispatch("setIsThemeFetched", true);
	}
};
</script>

<style lang="scss">
footer {
	z-index: 9;
	height: 115px;
	padding: 20px 30px;
	position: relative;
	background-color: #fff;
	border-top: 1px solid #d1d1d1;

	.row {
		display: flex;
		align-items: center;
	}

	ul {
		list-style: none;
		li {
			display: inline-block;
			margin: 0 10px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			a {
				color: #000 !important;
				opacity: 0.8;
				font-size: 14px;
				text-decoration: none;
			}
		}
	}
}
</style>
